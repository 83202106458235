import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    mini: true,
    token: '',
    users: {},
    mapSessionToken: '',
    singleOrderPayload: null,
    bulkOrderPayload: null,
    isBulk: false,
  },
  getters: {},
  mutations: {
    SET_MINI(state, payload) {
      state.mini = payload;
    },
    SET_CURRENT_USER(state, payload) {
      state.users = payload;
    },
    SET_MAP_SESSION_TOKEN(state, payload) {
      state.mapSessionToken = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_SINGLE_ORDER_PAYLOAD(state, payload) {
      state.singleOrderPayload = payload;
    },
    SET_BULK_ORDER_PAYLOAD(state, payload) {
      state.bulkOrderPayload = payload;
    },
    SET_IS_BULK_PAYLOAD(state, payload) {
      state.isBulk = payload;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
