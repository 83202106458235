<template>
  <router-view />
</template>
<script>
import { useToast } from 'vue-toastification';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    return {
      router,
      route,
      toast,
    };
  },
};
</script>
<style lang="scss">
body {
  font-size: 14px;
  color: #303b4d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
}

td,
th {
  text-align: left;
  font-weight: inherit;
}

ul,
ol {
  @apply pl-4;
  li {
    @apply mb-2;
  }
}

textarea:focus,
input:focus {
  outline: none;
}

#app {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.Vue-Toastification__toast.custom-toast {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 56px;
  @screen sm {
    min-width: 480px;
  }
}

.Vue-Toastification__toast-body.custom-body {
  font-family: Inter, sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.highlight {
  @apply rounded;
  @apply bg-grey-4;
  @apply bg-opacity-50;
  @apply px-2;
}

.button-merchant {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.v-field--rounded {
  border-radius: 10px !important;
}
.btn-green-merchant {
  width: 81px;
  height: 28px;
  background: #37b64f !important;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  color: white;
  font-size: 12px;
}

.btn-red-merchant {
  width: 81px;
  height: 28px;
  background: #c33a2c !important;
  border-radius: 10px;
  color: white;
  font-size: 12px;
}

#bg-merchant {
  // background-image: url('./assets/background-sidebar.png');
  background: #c33a2c;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url('./assets/background-sidebar3.png');
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 30px 30px 0px;
}
</style>
