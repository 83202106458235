import { createApp } from 'vue';
import Toast from 'vue-toastification';
import VueGoogleMaps from '@fawmi/vue-google-maps';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueBarcode from '@chenfengyuan/vue-barcode';
// eslint-disable-next-line import/no-extraneous-dependencies
import maska from 'maska';
// eslint-disable-next-line import/no-extraneous-dependencies
import excel from 'vue-excel-export';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import 'vue-toastification/dist/index.css';
import './assets/tailwind.css';

const toastOption = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
  containerClassName: 'custom-container',
  toastClassName: 'custom-toast',
  bodyClassName: 'custom-body',
};

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(Toast, toastOption)
  .use(maska)
  .use(excel)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    },
  })
  .component(VueBarcode.name, VueBarcode)
  .mount('#app');
